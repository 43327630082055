<template>
  <div>
    <transition name="fade">
      <form-wizard
        color="#0078d4"
        :title="null"
        :subtitle="null"
        shape="square"
        :finish-button-text="buttonLabel"
        :next-button-text="buttonLabelProximo"
        back-button-text="Anterior"
        class="mb-3"
        @on-complete="salvarFormulario"
      >
        <!-- Cadastro de usuário: Dados básicos -->
        <tab-content
          title="Detalhes do usuário"
          :before-change="validaDadosBasicos"
        >
          <validation-observer
            ref="regrasDadosBasicos"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Dados básicos do usuário
                </h5>
                <!-- <small class="text-muted">
                Entre com as informações para cadastrar um novo usuário.
              </small> -->
              </b-col>
              <!-- Código do cliente -->
              <!-- <b-col md="6">
              <b-form-group
                label="Código da Empresa"
                label-for="idDoCliente"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Código da Empresa"
                  rules="required"
                >
                  <b-form-input
                    id="idDoCliente"
                    v-model="form.idDoCliente"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="123456789"
                    :disabled="
                      mode === 'V' || $store.state.user.idDoPerfil != 1
                    "
                    @blur="getClientName()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
              <!-- Nome do cliente -->
              <b-col md="6">
                <b-form-group
                  v-if="
                    $store.state.user.idDoPerfil != 1 ||
                      ($store.state.user.idDoPerfil == 1 && mode == 'V')
                  "
                  label="Nome da Empresa"
                  label-for="nomeDoCliente"
                >
                  <b-form-input
                    id="nomeDoCliente"
                    v-model="form.nomeDoCliente"
                    :disabled="
                      $store.state.user.idDoPerfil != 1 ||
                        ($store.state.user.idDoPerfil == 1 && mode == 'V')
                    "
                  />
                </b-form-group>
                <b-form-group
                  v-if="$store.state.user.idDoPerfil == 1 && mode != 'V'"
                >
                  <selecionar-cliente
                    :campo-obrigatorio="true"
                    :cliente-preenchido="clienteId"
                    @clienteSelecionado="selecionaCliente"
                  />
                </b-form-group>
              </b-col>
              <!-- Nome do usuário -->
              <b-col md="6">
                <b-form-group
                  label="Nome"
                  label-for="nomeDoUsuario"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nome"
                    rules="required"
                  >
                    <b-form-input
                      id="nomeDoUsuario"
                      v-model="form.nomeDoUsuario"
                      :state="errors.length > 0 ? false : null"
                      :disabled="mode === 'V'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- CPF do usuário -->
              <b-col md="6">
                <b-form-group
                  label="CPF"
                  label-for="cpfDoUsuario"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="CPF"
                    rules="required"
                  >
                    <cleave
                      id="cpfDoUsuario"
                      v-model="form.cpfDoUsuario"
                      :class="
                        errors.length > 0
                          ? 'form-control is-invalid'
                          : 'form-control'
                      "
                      :options="options.cpfDoUsuario"
                      :state="errors.length > 0 ? false : null"
                      :disabled="mode === 'V'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Email -->
              <b-col md="6">
                <b-form-group
                  label="Email"
                  label-for="emailDoUsuario"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="emailDoUsuario"
                      v-model="form.emailDoUsuario"
                      type="email"
                      :state="errors.length > 0 ? false : null"
                      :disabled="mode === 'V'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Senha -->
              <b-col
                v-if="mode === 'I'"
                md="6"
              >
                <b-form-group
                  label="Senha"
                  label-for="senhaDoUsuario"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Senha"
                    vid="Password"
                    rules="required|password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="senhaDoUsuario"
                        v-model="form.senhaDoUsuario"
                        :type="passwordFieldType"
                        class="form-control-merge"
                        :state="errors.length > 0 ? false : null"
                        :disabled="mode === 'V'"
                      />

                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Status -->
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Status"
                  rules="required"
                >
                  <b-form-group
                    label="Status"
                    label-for="regrasDadosBasicos"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="regrasDadosBasicos"
                      v-model="form.statusDoUsuario"
                      :options="statusDoUsuario"
                      placeholder="Selecione uma opção"
                      label="text"
                      :disabled="mode === 'V'"
                    >
                      <span slot="no-options">
                        Opção não encontrada
                      </span>
                    </v-select>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- Data de Nascimento -->
              <b-col md="6">
                <b-form-group
                  label="Data de nascimento"
                  label-for="dataNascimentoDoUsuario"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Data de nascimento"
                    rules="required"
                  >
                    <cleave
                      id="dataNascimentoDoUsuario"
                      v-model="form.dataNascimentoDoUsuario"
                      class="form-control"
                      :class="
                        errors.length > 0
                          ? 'form-control is-invalid'
                          : 'form-control'
                      "
                      :raw="false"
                      :options="options.dataNascimentoDoUsuario"
                      placeholder="DD/MM/AAAA"
                      :disabled="mode === 'V'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Perfil -->
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Perfil"
                  rules="required"
                >
                  <b-form-group
                    label="Perfil"
                    label-for="idDoPerfil"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="idDoPerfil"
                      v-model="form.idDoPerfil"
                      :options="idDoPerfil"
                      placeholder="Selecione uma opção"
                      :selectable="
                        option => !option.value.includes('select_value')
                      "
                      label="text"
                      :disabled="
                        mode === 'V' || $store.state.user.idDoPerfil === 5
                      "
                    >
                      <span slot="no-options">
                        Opção não encontrada
                      </span>
                    </v-select>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <!-- Cadastro de usuário: Dados do cargo -->
        <tab-content
          title="Dados adicionais"
          :before-change="validaDetalhe"
        >
          <validation-observer
            ref="regrasUsuarioDetalhe"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Dados adicionais
                </h5>
                <small class="text-muted">
                  Entre com as informações para cadastrar um novo usuário.
                </small>
              </b-col>
              <!-- Departamento -->
              <b-col md="6">
                <b-form-group
                  label="Departamento"
                  label-for="departamentoDoUsuario"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Departamento"
                    rules="required"
                  >
                    <b-form-input
                      id="departamentoDoUsuario"
                      v-model="form.departamentoDoUsuario"
                      :class="
                        errors.length > 0
                          ? 'form-control is-invalid'
                          : 'form-control'
                      "
                      :disabled="mode === 'V'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Área -->
              <b-col md="6">
                <b-form-group
                  label="Área"
                  label-for="areaDoUsuario"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Área"
                    rules="required"
                  >
                    <b-form-input
                      id="areaDoUsuario"
                      v-model="form.areaDoUsuario"
                      :class="
                        errors.length > 0
                          ? 'form-control is-invalid'
                          : 'form-control'
                      "
                      :disabled="mode === 'V'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Profissão -->
              <b-col md="6">
                <b-form-group
                  label="Profissão"
                  label-for="profissaoDoUsuario"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Profissão"
                    rules="required"
                  >
                    <b-form-input
                      id="profissaoDoUsuario"
                      v-model="form.profissaoDoUsuario"
                      :class="
                        errors.length > 0
                          ? 'form-control is-invalid'
                          : 'form-control'
                      "
                      :disabled="mode === 'V'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Cargo -->
              <b-col md="6">
                <b-form-group
                  label="Cargo"
                  label-for="funcaoDoUsuario"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Cargo"
                    rules="required"
                  >
                    <v-select
                      v-model="form.funcaoDoUsuario"
                      :disabled="mode === 'V'"
                      :options="rolesList"
                      label="descricaoDaFuncao"
                      placeholder="Selecione uma opção"
                      autocomplete="true"
                      :value="rolesList.idDaFuncao"
                      @input="setSelectedRole(form.funcaoDoUsuario.idDaFuncao)"
                    >
                      <span slot="no-options">
                        Opção não encontrada
                      </span>
                    </v-select>
                    <!-- <b-form-input
                    id="funcaoDoUsuario"
                    v-model="form.funcaoDoUsuario"
                    placeholder="Cargo"
                    :class="
                      errors.length > 0
                        ? 'form-control is-invalid'
                        : 'form-control'
                    "
                    :disabled="mode === 'V'"
                  /> -->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Local de trabalho -->
              <b-col md="6">
                <b-form-group
                  label="Local de trabalho"
                  label-for="localDeTrabalhoDoUsuario"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Local de Trabalho"
                    rules="required"
                  >
                    <v-select
                      v-model="form.localDeTrabalhoDoUsuario"
                      :disabled="mode === 'V'"
                      :options="localTrabalhoList"
                      label="titulo"
                      autocomplete="true"
                      :value="localTrabalhoList.id"
                      @input="
                        selecionaLocalTrabalho(form.localDeTrabalhoDoUsuario.id)
                      "
                    >
                      <span slot="no-options">
                        Opção não encontrada
                      </span>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                  <!-- <validation-provider
                  #default="{ errors }"
                  vid="localDeTrabalhoDoUsuario"
                  name="Local de trabalho"
                  rules="required"
                >
                  <b-form-input
                    id="localDeTrabalhoDoUsuario"
                    v-model="form.localDeTrabalhoDoUsuario"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Local de trabalho"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
                </b-form-group>
              </b-col>
              <!-- Escolaridade -->
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Formação"
                  rules="required"
                >
                  <b-form-group
                    label="Escolaridade"
                    label-for="formacao"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="formacao"
                      v-model="form.escolaridadeDoUsuario"
                      :options="escolaridadeDoUsuario"
                      placeholder="Selecione uma opção"
                      :disabled="mode === 'V'"
                      label="text"
                    >
                      <span slot="no-options">
                        Opção não encontrada
                      </span>
                    </v-select>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- Estado de nascimento -->
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Estado de nascimento"
                  rules="required"
                >
                  <b-form-group
                    label="Estado de nascimento"
                    label-for="estado-nascimento"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="estado-nascimento"
                      v-model="form.ufNascimentoDoUsuario"
                      :options="ufNascimentoDoUsuario"
                      placeholder="Selecione uma opção"
                      :selectable="
                        option => !option.value.includes('select_value')
                      "
                      :disabled="mode === 'V'"
                      label="text"
                    >
                      <span slot="no-options">
                        Opção não encontrada
                      </span>
                    </v-select>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- Cidade de nascimento -->
              <b-col md="6">
                <b-form-group
                  label="Cidade de nascimento"
                  label-for="cidadeDeNascimento"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Cidade de nascimento"
                    rules="required"
                  >
                    <b-form-input
                      id="cidadeDeNascimento"
                      v-model="form.cidadeDeNascimento"
                      :class="
                        errors.length > 0
                          ? 'form-control is-invalid'
                          : 'form-control'
                      "
                      :disabled="mode === 'V'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Gerente -->
              <b-col md="6">
                <b-form-group
                  label="Gerente"
                  label-for="gerente"
                >
                  <v-select
                    id="gerente"
                    v-model="form.gerenteDoUsuario"
                    placeholder="Nenhum"
                    label="text"
                    :options="listaGerentes"
                    autocomplete="true"
                    :value="rolesList.idDaFuncao"
                    :selectable="
                      option => !option.value.includes('select_value')
                    "
                    :disabled="mode === 'V'"
                  >
                    <span slot="no-options">
                      Opção não encontrada
                    </span>
                  </v-select>
                </b-form-group>
              </b-col>
              <!-- Gestor -->
              <b-col md="6">
                <b-form-group
                  label="Gestor"
                  label-for="gestor"
                >
                  <v-select
                    id="gestor"
                    v-model="form.gestorDoUsuario"
                    :options="listaGestores"
                    :selectable="
                      option => !option.value.includes('select_value')
                    "
                    :disabled="mode === 'V'"
                    placeholder="Nenhum"
                    label="text"
                  >
                    <span slot="no-options">
                      Opção não encontrada
                    </span>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </transition>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard"
import vSelect from "vue-select"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { getUserData } from "@/auth/utils"
import "vue-form-wizard/dist/vue-form-wizard.min.css"
import store from "@/store"
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupAppend
} from "bootstrap-vue"
import { required, email } from "@validations"
import Cleave from "vue-cleave-component"
import { togglePasswordVisibility } from "@core/mixins/ui/forms"
import useJwt from "@/auth/jwt/useJwt"
import { codeIcon, codeMask } from "./code"
import SelecionarCliente from "@/views/global/SelecionarCliente.vue"

export default {
  components: {
    ValidationProvider,
    BInputGroup,
    BInputGroupAppend,
    ValidationObserver,
    Cleave,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    SelecionarCliente,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  mixins: [togglePasswordVisibility],
  props: {
    mode: {
      type: String, // I -> Incluir || A -> Alterar || V -> Visualização
      required: true
    },
    // eslint-disable-next-line vue/require-default-prop
    userId: {
      type: Number,
      required: false
    },
    // eslint-disable-next-line vue/require-default-prop
    clientId: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      codeMask,
      required,
      codeIcon,
      email,
      buttonLabel: "Salvar",
      buttonLabelProximo: "Próximo",
      bloqEditperfil: false,
      getidDoCliente: "",
      clienteId: 0,
      usuarioId: 0,
      perfilId: 0,

      form: {
        // Dados Usuário
        idDoUsuario: 0,
        idDoCliente: null,
        idDoGerente: 0,
        idDoGestor: 0,
        idDaBase: 0,
        nomeDoCliente: null,
        nomeDoUsuario: null,
        cpfDoUsuario: null,
        departamentoDoUsuario: null,
        areaDoUsuario: null,
        profissaoDoUsuario: null,
        funcaoDoUsuario: null,
        emailDoUsuario: null,
        senhaDoUsuario: null,
        statusDoUsuario: null,
        localDeTrabalhoDoUsuario: null,
        dataNascimentoDoUsuario: null,
        escolaridadeDoUsuario: null,
        ufNascimentoDoUsuario: null,
        cidadeDeNascimento: null,
        idDoPerfil: null,
        gerenteDoUsuario: 0,
        gestorDoUsuario: 0,
        idDoCargo: 0
      },
      options: {
        urlSeparada: null,
        exibirCampoSenha: true,
        isSenhaRequired: true,
        isDisabled: false,
        cpfDoUsuario: {
          delimiters: [".", ".", "-"],
          blocks: [3, 3, 3, 2],
          numericOnly: true
        },
        dataNascimentoDoUsuario: {
          date: true,
          delimiter: "/",
          datePattern: ["d", "m", "Y"]
        }
      },
      escolaridadeDoUsuario: [
        { value: "select_value", text: "Selecione uma opção" },
        { value: "1", text: "Fundamental incompleto" },
        { value: "2", text: "Fundamental cursando" },
        { value: "3", text: "Fundamental completo" },
        { value: "4", text: "Ensino médio incompleto" },
        { value: "5", text: "Ensino médio cursando" },
        { value: "6", text: "Ensino médio completo" },
        { value: "7", text: "Ensino superior incompleto" },
        { value: "8", text: "Ensino superior cursando" },
        { value: "9", text: "Ensino superior completo" }
      ],
      statusDoUsuario: [
        { value: "A", text: "Ativo" },
        { value: "F", text: "Férias" },
        { value: "T", text: "Afastado" },
        { value: "D", text: "Inativo" }
      ],
      ufNascimentoDoUsuario: [
        { text: "Acre", value: "AC" },
        { text: "Alagoas", value: "AL" },
        { text: "Amapá", value: "AP" },
        { text: "Amazonas", value: "AM" },
        { text: "Bahia", value: "BA" },
        { text: "Ceará", value: "CE" },
        { text: "Distrito Federal", value: "DF" },
        { text: "Espírito Santo", value: "ES" },
        { text: "Goiás", value: "GO" },
        { text: "Maranhão", value: "MA" },
        { text: "Mato Grosso", value: "MT" },
        { text: "Mato Grosso do Sul", value: "MS" },
        { text: "Minas Gerais", value: "MG" },
        { text: "Pará", value: "PA" },
        { text: "Paraíba", value: "PB" },
        { text: "Paraná", value: "PR" },
        { text: "Pernambuco", value: "PE" },
        { text: "Piauí", value: "PI" },
        { text: "Rio de Janeiro", value: "RJ" },
        { text: "Rio Grande do Norte", value: "RN" },
        { text: "Rio Grande do Sul", value: "RS" },
        { text: "Rondônia", value: "RO" },
        { text: "Roraima", value: "RR" },
        { text: "Santa Catarina", value: "SC" },
        { text: "São Paulo", value: "SP" },
        { text: "Sergipe", value: "SE" },
        { text: "Tocantins", value: "TO" }
      ],
      idDoPerfil: [
        { value: "select_value", text: "Selecione uma opção" },
        { value: "1", text: "Master" },
        { value: "2", text: "Administrador" },
        { value: "3", text: "Gerente" },
        { value: "4", text: "Gestor" },
        { value: "5", text: "Usuário" }
      ],
      idDoPerfilMaster: [
        { value: "select_value", text: "Selecione uma opção" },
        { value: "1", text: "Master" },
        { value: "2", text: "Administrador" },
        { value: "3", text: "Gerente" },
        { value: "4", text: "Gestor" },
        { value: "5", text: "Usuário" }
      ],

      idDoPerfilAdministrador: [
        { value: "select_value", text: "Selecione uma opção" },
        { value: "2", text: "Administrador" },
        { value: "3", text: "Gerente" },
        { value: "4", text: "Gestor" },
        { value: "5", text: "Usuário" }
      ],

      idDoPerfilGerente: [
        { value: "select_value", text: "Selecione uma opção" },
        { value: "3", text: "Gerente" },
        { value: "4", text: "Gestor" },
        { value: "5", text: "Usuário" }
      ],

      idDoPerfilGestor: [
        { value: "select_value", text: "Selecione uma opção" },
        { value: "4", text: "Gestor" },
        { value: "5", text: "Usuário" }
      ],
      listaGerentes: [{ text: "Selecione uma opção", value: "select_value" }],
      listaGestores: [{ text: "Selecione uma opção", value: "select_value" }],
      listaGerentesFiltrada: [],
      listaGestoresFiltrada: [],
      listaClientes: [{ text: "Selecione uma opção", value: "select_value" }],
      rolesList: [],
      localTrabalhoList: [],
      blockInput: 0
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "senhaDoUsuario"
        ? "EyeIcon"
        : "EyeOffIcon"
    }
  },
  beforeMount() {
    this.getCliente(false)
  },
  mounted() {
    this.form.idDoCliente = Number(getUserData().idDoCliente)
    if (this.mode === "V") {
      this.buttonLabel = "Fechar"
    } else {
      this.buttonlabel = "Salvar"
    }
    if (this.mode !== "I") {
      this.consultarUsuario()
      this.usuarioId = parseInt(this.$route.params.idDoUsuario)
      this.clienteId = parseInt(this.$route.params.idDoCliente)
      this.form.idDoCliente = this.clienteId
      // this.CarregaPerfil()
    } else {
      this.getClientName()

      // this.buscarGestoresCliente()
    }
    // Monta lista perfis que pode ser selecionada ao criar novo usuario
    this.DadosCampoDePerfil()
  },
  methods: {
    selecionaCliente(cliente) {
      const obj = JSON.parse(JSON.stringify(cliente))
      this.getidDoCliente = obj.idDoCliente
      this.getClientName()
    },
    tipoDePath() {
      const url = String(this.$router.history.current.path)
      this.options.urlSeparada = url.split("/")
      // Se for visualização vai preencher os campos e desabilitar a edição
      if (this.options.urlSeparada[1] === "visualizacao-de-usuario") {
        this.consultarUsuario()
        this.options.exibirCampoSenha = false
        this.options.isDisabled = true
      }
      if (this.options.urlSeparada[1] === "alteracao-de-usuario") {
        this.consultarUsuario()
        this.options.isSenhaRequired = false
        this.options.exibirCampoSenha = true
        this.options.isDisabled = false
      }
      // return urlSeparada[1] === 'cadastro-de-usuario' ||
    },
    async getListLocalTrabalho(id) {
      const resp = await useJwt
        .consultaLocalPorCliente(id)
        .then(response => response.data)
        .catch(response => response.data)
      if (resp.sucesso) {
        this.localTrabalhoList = resp.dados
      } else {
        return this.MensagemToastConsultaLocalTrabalho("danger", "top-center")
      }
    },

    selecionaLocalTrabalho(id) {
      this.form.idDaBase = id
    },

    setSelectedRole(id) {
      this.form.idDoCargo = id
    },
    async consultarFuncao(id) {
      if (id > 0) {
        const obj = {
          descricaoDaFuncao: "",
          idDoCliente: "",
          idDaFuncao: ""
        }
        const response = await useJwt
          .consultaCargo(id)
          .then(response => response.data)
          .catch(response => response.data)
        if (response.sucesso) {
          obj.descricaoDaFuncao =
            response.dados.consultaFuncao.descricaoDaFuncao
          obj.idDoCliente = response.dados.consultaFuncao.idDoCliente
          obj.idDaFuncao = id
          this.form.funcaoDoUsuario = obj.descricaoDaFuncao
        } else {
          return this.MensagemToastErrorConsultaCargo("danger", "top-center")
        }
      }
    },
    async consultarUsuario() {
      const idDoCliente = this.clientId
      const idDoUsuario = this.userId
      this.getClientName()

      const resp = await useJwt
        .consultarUsuario(idDoCliente, idDoUsuario)
        .then(response => response.data)
        .catch(response => response.data)
      if (resp.sucesso) {
        const usuarioEncontrado = resp.dados.consultarUsuario
        this.form.idDoCargo = usuarioEncontrado.idFuncaoDoUsuario
        this.form.idDoUsuario = usuarioEncontrado.idDoUsuario
        this.form.idDoCliente = usuarioEncontrado.idDoCliente
        this.form.nomeDoCliente = usuarioEncontrado.nomeDoCliente
        this.form.nomeDoUsuario = usuarioEncontrado.nomeDoUsuario
        this.form.cpfDoUsuario = usuarioEncontrado.cpfDoUsuario
        this.form.departamentoDoUsuario =
          usuarioEncontrado.departamentoDoUsuario
        this.form.areaDoUsuario = usuarioEncontrado.areaDoUsuario
        this.form.profissaoDoUsuario = usuarioEncontrado.profissaoDoUsuario
        this.form.funcaoDoUsuario = this.consultarFuncao(
          usuarioEncontrado.idFuncaoDoUsuario
        )
        this.form.emailDoUsuario = usuarioEncontrado.emailDoUsuario
        this.form.statusDoUsuario = this.statusDoUsuario.find(
          o => o.value === usuarioEncontrado.statusDoUsuario
        )
        this.form.localDeTrabalhoDoUsuario = usuarioEncontrado.titulo
        this.form.idDaBase = usuarioEncontrado.idDaBase
        this.form.dataNascimentoDoUsuario = this.formataStringData(
          usuarioEncontrado.dataDeNascimentoDoUsuario,
          2
        )
        this.form.escolaridadeDoUsuario = this.escolaridadeDoUsuario.find(
          o => o.value === String(usuarioEncontrado.escolaridadeDoUsuario)
        )

        this.form.ufNascimentoDoUsuario = this.ufNascimentoDoUsuario.find(
          o => o.value === usuarioEncontrado.ufNascimentoDoUsuario
        )
        this.form.cidadeDeNascimento = usuarioEncontrado.cidadeDeNascimento
        this.form.idDoPerfil = this.idDoPerfil.find(
          o => o.value === String(usuarioEncontrado.idDoPerfil)
        )
        this.form.idDoGerente = usuarioEncontrado.idDoGerente

        this.form.idDoGestor = usuarioEncontrado.idDoGestor

        // this.buscarGerentesCliente(this.form.idDoCliente)
        this.buscarGerentesCliente(usuarioEncontrado.idDoGerente)
      }
    },
    async getRoles(id) {
      if (id > 0) {
        const response = await useJwt
          .listRolesCliente(id)
          .then(response => response.data)
          .catch(response => {
            this.rolesList = []
            this.form.funcaoDoUsuario = ""
            this.form.idDaFuncao = 0
          })
        if (response.sucesso) {
          this.rolesList = Object.assign(response.dados.listaFuncao)
        } else {
          this.rolesList = []
          return this.MensagemToastErrorConsultaCargo("danger", "top-center")
        }
      }
    },
    async buscarGestoresCliente(id) {
      const resp = await useJwt
        .getGestorPorIdCliente(id)
        .then(response => response.data)
        .catch(response => response.data)
      this.listaGestores = []
      if (resp.sucesso)
        resp.dados.usuarios.forEach(element => {
          const manager = {
            text: element.nomeDoGestor,
            value: element.idDoGestor.toString()
          }
          this.listaGestores.push(manager)
        })
      this.form.gestorDoUsuario = this.listaGestores.find(
        o => o.value === String(this.form.idDoGestor)
      )
    },
    async buscarGerentesCliente(idCliente) {
      const resp = await useJwt
        .getGerentePorIdCliente(idCliente)
        .then(response => response.data)
        .catch(response => response.data)
      this.listaGerentes = []
      if (resp.sucesso) {
        resp.dados.usuarios.forEach(element => {
          const manager = {
            text: element.nomeDoGerente,
            value: element.idDoGerente.toString()
          }
          this.listaGerentes.push(manager)
        })
        // Preenche o input de Gerente na consulta
        this.form.gerenteDoUsuario = this.listaGerentes.find(
          o => o.value === String(this.form.idDoGerente)
        )
      }
    },
    gerarDadosCliente() {
      const cliente = this.listaClientes.find(
        o => o.idDoCliente === parseInt(this.form.idDoCliente, 10)
      )
      this.form.nomeDoCliente = cliente.nomeDoCliente
    },
    async getGestor() {
      const resp = await useJwt
        .getGestor()
        .then(response => response.data)
        .catch(response => response.data)

      if (resp.sucesso) {
        Object.entries(resp.dados.usuarios).forEach(e =>
          this.listaGestores.push({
            value: String(e[1].idDoGestor),
            text: e[1].nomeDoGestor
          })
        )
        // this.buscarGestoresCliente()
      } else {
        // console.log('Erro')
        //  Faz o tratamento de erro na tela
      }
    },
    async getGerente() {
      const resp = await useJwt
        .getGerente()
        .then(response => response.data)
        .catch(response => response.data)

      if (resp.sucesso) {
        Object.entries(resp.dados.usuarios).forEach(e =>
          this.listaGerentes.push({
            value: String(e[1].idDoGerente),
            text: e[1].nomeDoGerente
          })
        )
        // this.buscarGerentesCliente()
      } else {
        // console.log('Erro')
        //  Faz o tratamento de erro na tela
      }
    },
    async getCliente() {
      const resp = await useJwt
        .getListaDeClientes()
        .then(response => response.data)
        .catch(response => {
          response.data
        })

      if (resp.sucesso) {
        Object.entries(resp.dados.usuarios).forEach(e =>
          this.listaClientes.push({
            idDoCliente: e[1].idDoCliente,
            nomeDoCliente: e[1].nomeDoCliente
          })
        )
      } else {
        this.MensagemToastErrorConsultaCliente("danger", "top-center")
      }
    },
    getClientName() {
      setTimeout(() => {
        if (this.getidDoCliente) {
          this.form.idDoCliente = this.getidDoCliente
          this.getCliente(this.form.idDoCliente)
          this.getRoles(this.form.idDoCliente)
          this.getListLocalTrabalho(this.form.idDoCliente)
        } else {
          this.getCliente(this.form.idDoCliente)
          this.getRoles(this.form.idDoCliente)
          this.getListLocalTrabalho(this.form.idDoCliente)
        }
        if (Object.keys(this.listaClientes).length < 1) {
        }
        this.buscarGerentesCliente(this.form.idDoCliente)
        this.buscarGestoresCliente(this.form.idDoCliente)
        let found = false
        this.listaClientes.forEach(e => {
          if (e.idDoCliente === parseInt(this.form.idDoCliente)) {
            this.form.nomeDoCliente = e.nomeDoCliente
            found = true
          }
        })

        if (!found) {
          this.form.nomeDoCliente = ""
          return this.MensagemToastErrorEncontraCliente("danger", "top-center")
        }
      }, 2000)
    },

    getClienteSessao(usuario) {
      this.form.idDoCliente = usuario.idDoCliente
    },

    formataStringData(data, padrao) {
      // Padrão 1: Entra DD/MM/YYYY ---> sai YYYY-MM-DD
      // Else : Entra YYYY-MM-DD    ---> sai DD/MM/YYYY
      if (padrao === 1) {
        const dia = data.split("/")[0]
        const mes = data.split("/")[1]
        const ano = data.split("/")[2]
        const diaSlice = `0${dia}`.slice(-2)
        const mesSlice = `0${mes}`.slice(-2)
        // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
        return `${ano}-${mesSlice}-${diaSlice}`
      }
      const dia = data.split("-")[2].substring(0, 2)
      const diaSlice = `0${dia}`.slice(-2)
      const mes = data.split("-")[1]
      const mesSlice = `0${mes}`.slice(-2)
      const ano = data.split("-")[0]
      return `${diaSlice}/${mesSlice}/${ano}`
    },
    async cadastroUsuario() {
      const resp = await useJwt
        .cadastroUsuario({
          idDoCliente: parseInt(this.form.idDoCliente),
          nomeDoUsuario: this.form.nomeDoUsuario,
          cpfDoUsuario: this.form.cpfDoUsuario,
          departamentoDoUsuario: this.form.departamentoDoUsuario,
          areaDoUsuario: this.form.areaDoUsuario,
          profissaoDoUsuario: this.form.profissaoDoUsuario,
          idFuncaoDoUsuario: this.form.idDoCargo,
          emailDoUsuario: this.form.emailDoUsuario,
          senhaDoUsuario: this.form.senhaDoUsuario,
          statusDoUsuario: this.form.statusDoUsuario.value,
          idDaBase: this.form.localDeTrabalhoDoUsuario.id,
          dataNascimentoDoUsuario: this.formataStringData(
            this.form.dataNascimentoDoUsuario,
            1
          ),
          escolaridadeDoUsuario: parseInt(
            this.form.escolaridadeDoUsuario.value,
            10
          ),
          ufNascimentoDoUsuario: this.form.ufNascimentoDoUsuario.value,
          cidadeDeNascimento: this.form.cidadeDeNascimento,
          idDoPerfil: parseInt(this.form.idDoPerfil.value, 10),
          // Se gerente ou gestor não forem selecionados irá enviar um zero
          idDoGerente:
            typeof this.form.gerenteDoUsuario === "undefined" ||
            this.form.gerenteDoUsuario === null
              ? 0
              : parseInt(this.form.gerenteDoUsuario.value, 10),
          idDoGestor:
            typeof this.form.gestorDoUsuario === "undefined" ||
            this.form.gestorDoUsuario === null
              ? 0
              : parseInt(this.form.gestorDoUsuario.value, 10)
        })
        .then(response => response.data)
        .catch(response => {
          const catchError = Object.assign({}, response)
          this.MensagemToastErrorIncluirUsuario(
            "danger",
            "top-center",
            catchError.response.data.mensagem
          )
        })
      if (resp.sucesso) {
        this.options.isDisabled = true
        this.MensagemToastCadastrarUsuario("success", "top-center")

        this.$router.push("/usuarios")
      } else {
        this.MensagemToastErrorIncluirUsuario("danger", "top-center")
      }
    },
    async alterarUsuario() {
      const resp = await useJwt
        .alterarUsuario(
          {
            idDoCliente: parseInt(this.form.idDoCliente, 10),
            nomeDoUsuario: this.form.nomeDoUsuario,
            cpfDoUsuario: this.form.cpfDoUsuario,
            departamentoDoUsuario: this.form.departamentoDoUsuario,
            areaDoUsuario: this.form.areaDoUsuario,
            profissaoDoUsuario: this.form.profissaoDoUsuario,
            idFuncaoDoUsuario: this.form.idDoCargo,
            emailDoUsuario: this.form.emailDoUsuario,
            // senhaDoUsuario: this.form.senhaDoUsuario,
            statusDoUsuario: this.form.statusDoUsuario.value,
            idDaBase: this.form.idDaBase,
            dataNascimentoDoUsuario: this.formataStringData(
              this.form.dataNascimentoDoUsuario,
              1
            ),
            escolaridadeDoUsuario: parseInt(
              this.form.escolaridadeDoUsuario.value,
              10
            ),
            ufNascimentoDoUsuario: this.form.ufNascimentoDoUsuario.value,
            cidadeDeNascimento: this.form.cidadeDeNascimento,
            idDoPerfil: parseInt(this.form.idDoPerfil.value, 10),

            // Se gerente ou gestor não forem selecionados irá enviar um zero
            idDoGerente:
              typeof this.form.gerenteDoUsuario === "undefined" ||
              this.form.gerenteDoUsuario === null
                ? 0
                : parseInt(this.form.gerenteDoUsuario.value, 10),
            idDoGestor:
              typeof this.form.gestorDoUsuario === "undefined" ||
              this.form.gestorDoUsuario === null
                ? 0
                : parseInt(this.form.gestorDoUsuario.value, 10)
          },
          this.form.idDoUsuario
        )
        .then(response => response.data)
        .catch(response => {
          this.importErrors = Object.assign({}, response)
          this.errorUsers = []
          this.errorUsers = Object.assign(
            [],
            this.importErrors.response.data.mensagem
          )
          this.MensagemToastErrorAtualizarUsuario(
            "danger",
            "top-center",
            this.importErrors.response.data.mensagem
          )
        })

      if (resp.sucesso) {
        this.options.isDisabled = true
        this.MensagemToastAtualizarUsuario("success", "top-center")

        this.$router.push("/usuarios")
      } else {
        this.MensagemToastErrorAtualizarUsuario(
          "danger",
          "top-center",
          resp.mensagem
        )
      }
    },
    salvarFormulario() {
      if (this.mode === "V") {
        this.$router.push({ name: "user-page" })
      }
      if (this.mode !== "V") {
        if (this.mode === "I") {
          this.perfilId = Number(getUserData().idDoPerfil)
          if (this.perfilId == 1) {
            this.form.idDoCliente = this.getidDoCliente
            this.cadastroUsuario()
          } else {
            this.cadastroUsuario()
          }
        } else if (this.mode === "A") {
          // this.alterarUsuario()
          this.VerificaPerfilAlterar()
        }
      }
    },
    async validaDadosBasicos() {
      const resp = await useJwt
        .validarCamposUsuario({
          idUsuario: this.form.idDoUsuario,
          cpf: this.form.cpfDoUsuario,
          email: this.form.emailDoUsuario,
          dataNascimento: this.formataStringData(
            this.form.dataNascimentoDoUsuario,
            1
          )
        })
        .then(response => response.data)
        .catch(response => {
          this.importErrors = Object.assign({}, response)
          this.errorUsers = []
          this.errorUsers = Object.assign(
            [],
            this.importErrors.response.data.mensagem
          )
          this.MensagemToastValidaCamposUsuario(
            "danger",
            "top-center",
            this.importErrors.response.data.mensagem
          )
        })
      if (resp.sucesso) {
        return new Promise((resolve, reject) => {
          this.$refs.regrasDadosBasicos.validate().then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
        })
      }
    },
    validaDetalhe() {
      return new Promise((resolve, reject) => {
        this.$refs.regrasUsuarioDetalhe.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    // CarregaPerfil() {
    //   const idDoPerfil = Number(getUserData().idDoPerfil)
    //   if (idDoPerfil != 1) {
    //     this.idDoPerfil = this.idDoPerfilAdministrador
    //   }
    //   // if (idDoPerfil == 5) {
    //   //   bloqEditperfil = true
    //   // }
    // },

    DadosCampoDePerfil() {
      switch (this.$store.state.user.idDoPerfil) {
        case 1:
          this.idDoPerfil = this.idDoPerfilMaster
          break
        case 2:
          this.idDoPerfil = this.idDoPerfilAdministrador
          break
        case 3:
          this.idDoPerfil = this.idDoPerfilGerente
          break
        case 4:
          this.idDoPerfil = this.idDoPerfilGestor
          break
      }
    },

    async VerificaPerfilAlterar() {
      try {
        const idDoCliente = Number(getUserData().idDoCliente)
        const idDoUsuario = Number(getUserData().idDoUsuario)
        const resp = await useJwt
          .consultarUsuario(idDoCliente, idDoUsuario)
          .then(response => response.data)
          .catch(response => response.data)
        if (resp.sucesso) {
          const idDoPerfil = parseInt(this.form.idDoPerfil.value)
          if (
            idDoPerfil >= resp.dados.consultarUsuario.idDoPerfil ||
            resp.dados.consultarUsuario.idDoPerfil === 1
          ) {
            if (this.getidDoCliente) {
              this.form.idDoCliente = this.getidDoCliente
            }
            this.alterarUsuario()
          } else {
            return this.MensagemToastPimissaoUsuario("warning", "top-center")
          }
        }
      } catch (error) {
        return this.MensagemToastVerificaPermissaoUsuario(
          "danger",
          "top-center"
        )
      }
    },

    async varficarCamposUsuario() {
      if (this.buttonLabelProximo === "Próximo") {
        const resp = await useJwt
          .validarCamposUsuario({
            cpf: this.form.cpfDoUsuario,
            email: this.form.emailDoUsuario
          })
          .then(response => response.data)
          .catch(response => {
            this.importErrors = Object.assign({}, response)
            this.errorUsers = []
            this.errorUsers = Object.assign(
              [],
              this.importErrors.response.data.mensagem
            )
            this.MensagemToastVerificaCamposUsuario(
              "danger",
              "top-center",
              this.importErrors.response.data.mensagem
            )
          })
        if (resp.sucesso) {
        }
      }
    },

    MensagemToastErrorConsultaCargo(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title:
              "Erro ao encontrar Cargos, Por Favor verifique os cargos cadastrados para esse cliente.",
            icon: "ErrorIcon",
            variant
          }
        },
        {
          position
        }
      )
    },
    MensagemToastErrorConsultaCliente(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title:
              "Erro ao listar clientes, Por favor, verifique sua conexão com a internet e tente novamente!",
            icon: "ErrorIcon",
            variant
          }
        },
        {
          position
        }
      )
    },
    MensagemToastErrorEncontraCliente(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Erro ao encontrar cliente.",
            icon: "ErrorIcon",
            variant,
            text: "Por favor efetue o login novamente!"
          }
        },
        {
          position
        }
      )
    },
    MensagemToastErrorIncluirUsuario(variant, position, mensagem) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Ocorreu um erro ao incluir o usuário.",
            icon: "CoffeeIcon",
            variant,
            text: mensagem
          }
        },
        {
          position
        }
      )
    },
    MensagemToastErrorAtualizarUsuario(variant, position, mensagem) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Erro ao atulizar cadastro. Por favor, tente novamente.",
            icon: "ErrorIcon",
            variant,
            text: mensagem
          }
        },
        {
          position
        }
      )
    },
    MensagemToastCadastrarUsuario(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Usuário incluído com sucesso!",
            icon: "CoffeeIcon",
            variant,
            text: "Você já pode realizar a inclusão de um novo usuário"
          }
        },
        {
          position
        }
      )
    },
    MensagemToastAtualizarUsuario(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Usuário alterado com sucesso!",
            icon: "CoffeeIcon",
            variant,
            text: "Você já pode realizar a inclusão de um novo usuário"
          }
        },
        {
          position
        }
      )
    },
    MensagemToastConsultaLocalTrabalho(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title:
              "Erro ao encontrar Local de Trabalho, Por Favor verifique os Locais de Trabalho cadastrados para esse Cliente.",
            icon: "ErrorIcon",
            variant
          }
        },
        {
          position
        }
      )
    },
    MensagemToastValidaCamposUsuario(variant, position, mensagem) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Por favor, verifique os campos novamente!",
            icon: "ErrorIcon",
            variant,
            text: mensagem
          }
        },
        {
          position
        }
      )
    },
    MensagemToastPimissaoUsuario(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title:
              "Desculpe, você não possui permisão para alterar esse usuário.",
            icon: "CoffeeIcon",
            variant,
            text: "Por favor, verificar se a solicitação está correta!"
          }
        },
        {
          position
        }
      )
    },
    MensagemToastVerificaPermissaoUsuario(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Erro ao verificar permissão de alteração",
            icon: "ErrorIcon",
            variant,
            text: "Por favor, tente novamente mais tarde!"
          }
        },
        {
          position
        }
      )
    },
    MensagemToastVerificaCamposUsuario(variant, position, mensagem) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Por favor, verifique os campos novamente!",
            icon: "ErrorIcon",
            variant,
            text: mensagem
          }
        },
        {
          position
        }
      )
    }
  }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
