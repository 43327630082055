<template>
  <div>
    <transition name="fade">
      <b-row>
        <b-col cols="12">
          <!-- v-if="isUserRegistration()" -->
          <user-registration
            v-if="showUsers"
            :user-id="userId"
            :client-id="clientId"
            :mode="mode"
          />
          <client-registration
            v-if="showClient"
            :id="id"
            :mode="mode"
          />
        </b-col>
      </b-row>
    </transition>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue"
import ClientRegistration from "./ClientRegistration.vue"
import UserRegistration from "./UserRegistration.vue"

export default {
  components: {
    BRow,
    BCol,
    ClientRegistration,
    UserRegistration
  },
  data() {
    return {
      mode: "",
      id: 0,
      clientId: 0,
      userId: 0,
      showClient: false,
      showUsers: false
    }
  },
  mounted() {
    this.handleRoute()
  },
  methods: {
    // isUserRegistration() {
    //   const url = String(this.$router.history.current.path)
    //   const urlSeparada = url.split('/')
    //   // Essa função vai retornar true se for
    //   // 'cadastro-de-usuario' ou se for 'visualizacao-de-usuario' ou 'alteracao-de-usuario'
    //   // Nesse caso vai chamar a view UserRegistration
    //   return (
    //     urlSeparada[1] === 'cadastro-de-usuario' ||
    //     urlSeparada[1] === 'visualizacao-de-usuario' ||
    //     urlSeparada[1] === 'alteracao-de-usuario'
    //   )
    // },
    handleRoute() {
      this.id = parseInt(this.$route.params.id)
      this.userId = parseInt(this.$route.params.idDoUsuario)
      this.clientId = parseInt(this.$route.params.idDoCliente)

      if (this.$route.path.includes("cliente")) {
        if (this.$route.path.includes("editar-cliente")) {
          this.mode = "A"
        } else if (this.$route.path.includes("consultar-cliente")) {
          this.mode = "V"
        } else {
          this.mode = "I"
        }

        this.showClient = true
      } else if (this.$route.path.includes("usuario")) {
        if (this.$route.path.includes("editar-usuario")) {
          this.mode = "A"
        } else if (this.$route.path.includes("consultar-usuario")) {
          this.mode = "V"
        } else {
          this.mode = "I"
        }
        this.showUsers = true
      }
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
