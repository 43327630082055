var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":"fade"}},[_c('form-wizard',{staticClass:"mb-3",attrs:{"color":"#0078d4","title":null,"subtitle":null,"shape":"square","finish-button-text":_vm.buttonLabel,"next-button-text":_vm.buttonLabelProximo,"back-button-text":"Anterior"},on:{"on-complete":_vm.salvarFormulario}},[_c('tab-content',{attrs:{"title":"Detalhes do usuário","before-change":_vm.validaDadosBasicos}},[_c('validation-observer',{ref:"regrasDadosBasicos",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Dados básicos do usuário ")])]),_c('b-col',{attrs:{"md":"6"}},[(
                  _vm.$store.state.user.idDoPerfil != 1 ||
                    (_vm.$store.state.user.idDoPerfil == 1 && _vm.mode == 'V')
                )?_c('b-form-group',{attrs:{"label":"Nome da Empresa","label-for":"nomeDoCliente"}},[_c('b-form-input',{attrs:{"id":"nomeDoCliente","disabled":_vm.$store.state.user.idDoPerfil != 1 ||
                      (_vm.$store.state.user.idDoPerfil == 1 && _vm.mode == 'V')},model:{value:(_vm.form.nomeDoCliente),callback:function ($$v) {_vm.$set(_vm.form, "nomeDoCliente", $$v)},expression:"form.nomeDoCliente"}})],1):_vm._e(),(_vm.$store.state.user.idDoPerfil == 1 && _vm.mode != 'V')?_c('b-form-group',[_c('selecionar-cliente',{attrs:{"campo-obrigatorio":true,"cliente-preenchido":_vm.clienteId},on:{"clienteSelecionado":_vm.selecionaCliente}})],1):_vm._e()],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nome","label-for":"nomeDoUsuario"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nomeDoUsuario","state":errors.length > 0 ? false : null,"disabled":_vm.mode === 'V'},model:{value:(_vm.form.nomeDoUsuario),callback:function ($$v) {_vm.$set(_vm.form, "nomeDoUsuario", $$v)},expression:"form.nomeDoUsuario"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"CPF","label-for":"cpfDoUsuario"}},[_c('validation-provider',{attrs:{"name":"CPF","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('cleave',{class:errors.length > 0
                        ? 'form-control is-invalid'
                        : 'form-control',attrs:{"id":"cpfDoUsuario","options":_vm.options.cpfDoUsuario,"state":errors.length > 0 ? false : null,"disabled":_vm.mode === 'V'},model:{value:(_vm.form.cpfDoUsuario),callback:function ($$v) {_vm.$set(_vm.form, "cpfDoUsuario", $$v)},expression:"form.cpfDoUsuario"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"emailDoUsuario"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"emailDoUsuario","type":"email","state":errors.length > 0 ? false : null,"disabled":_vm.mode === 'V'},model:{value:(_vm.form.emailDoUsuario),callback:function ($$v) {_vm.$set(_vm.form, "emailDoUsuario", $$v)},expression:"form.emailDoUsuario"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.mode === 'I')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Senha","label-for":"senhaDoUsuario"}},[_c('validation-provider',{attrs:{"name":"Senha","vid":"Password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"senhaDoUsuario","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"disabled":_vm.mode === 'V'},model:{value:(_vm.form.senhaDoUsuario),callback:function ($$v) {_vm.$set(_vm.form, "senhaDoUsuario", $$v)},expression:"form.senhaDoUsuario"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2856230785)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Status","label-for":"regrasDadosBasicos","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"regrasDadosBasicos","options":_vm.statusDoUsuario,"placeholder":"Selecione uma opção","label":"text","disabled":_vm.mode === 'V'},model:{value:(_vm.form.statusDoUsuario),callback:function ($$v) {_vm.$set(_vm.form, "statusDoUsuario", $$v)},expression:"form.statusDoUsuario"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Opção não encontrada ")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Data de nascimento","label-for":"dataNascimentoDoUsuario"}},[_c('validation-provider',{attrs:{"name":"Data de nascimento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",class:errors.length > 0
                        ? 'form-control is-invalid'
                        : 'form-control',attrs:{"id":"dataNascimentoDoUsuario","raw":false,"options":_vm.options.dataNascimentoDoUsuario,"placeholder":"DD/MM/AAAA","disabled":_vm.mode === 'V'},model:{value:(_vm.form.dataNascimentoDoUsuario),callback:function ($$v) {_vm.$set(_vm.form, "dataNascimentoDoUsuario", $$v)},expression:"form.dataNascimentoDoUsuario"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Perfil","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Perfil","label-for":"idDoPerfil","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"idDoPerfil","options":_vm.idDoPerfil,"placeholder":"Selecione uma opção","selectable":function (option) { return !option.value.includes('select_value'); },"label":"text","disabled":_vm.mode === 'V' || _vm.$store.state.user.idDoPerfil === 5},model:{value:(_vm.form.idDoPerfil),callback:function ($$v) {_vm.$set(_vm.form, "idDoPerfil", $$v)},expression:"form.idDoPerfil"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Opção não encontrada ")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Dados adicionais","before-change":_vm.validaDetalhe}},[_c('validation-observer',{ref:"regrasUsuarioDetalhe",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Dados adicionais ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Entre com as informações para cadastrar um novo usuário. ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Departamento","label-for":"departamentoDoUsuario"}},[_c('validation-provider',{attrs:{"name":"Departamento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0
                        ? 'form-control is-invalid'
                        : 'form-control',attrs:{"id":"departamentoDoUsuario","disabled":_vm.mode === 'V'},model:{value:(_vm.form.departamentoDoUsuario),callback:function ($$v) {_vm.$set(_vm.form, "departamentoDoUsuario", $$v)},expression:"form.departamentoDoUsuario"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Área","label-for":"areaDoUsuario"}},[_c('validation-provider',{attrs:{"name":"Área","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0
                        ? 'form-control is-invalid'
                        : 'form-control',attrs:{"id":"areaDoUsuario","disabled":_vm.mode === 'V'},model:{value:(_vm.form.areaDoUsuario),callback:function ($$v) {_vm.$set(_vm.form, "areaDoUsuario", $$v)},expression:"form.areaDoUsuario"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Profissão","label-for":"profissaoDoUsuario"}},[_c('validation-provider',{attrs:{"name":"Profissão","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0
                        ? 'form-control is-invalid'
                        : 'form-control',attrs:{"id":"profissaoDoUsuario","disabled":_vm.mode === 'V'},model:{value:(_vm.form.profissaoDoUsuario),callback:function ($$v) {_vm.$set(_vm.form, "profissaoDoUsuario", $$v)},expression:"form.profissaoDoUsuario"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Cargo","label-for":"funcaoDoUsuario"}},[_c('validation-provider',{attrs:{"name":"Cargo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.mode === 'V',"options":_vm.rolesList,"label":"descricaoDaFuncao","placeholder":"Selecione uma opção","autocomplete":"true","value":_vm.rolesList.idDaFuncao},on:{"input":function($event){return _vm.setSelectedRole(_vm.form.funcaoDoUsuario.idDaFuncao)}},model:{value:(_vm.form.funcaoDoUsuario),callback:function ($$v) {_vm.$set(_vm.form, "funcaoDoUsuario", $$v)},expression:"form.funcaoDoUsuario"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Opção não encontrada ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Local de trabalho","label-for":"localDeTrabalhoDoUsuario"}},[_c('validation-provider',{attrs:{"name":"Local de Trabalho","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.mode === 'V',"options":_vm.localTrabalhoList,"label":"titulo","autocomplete":"true","value":_vm.localTrabalhoList.id},on:{"input":function($event){return _vm.selecionaLocalTrabalho(_vm.form.localDeTrabalhoDoUsuario.id)}},model:{value:(_vm.form.localDeTrabalhoDoUsuario),callback:function ($$v) {_vm.$set(_vm.form, "localDeTrabalhoDoUsuario", $$v)},expression:"form.localDeTrabalhoDoUsuario"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Opção não encontrada ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Formação","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Escolaridade","label-for":"formacao","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"formacao","options":_vm.escolaridadeDoUsuario,"placeholder":"Selecione uma opção","disabled":_vm.mode === 'V',"label":"text"},model:{value:(_vm.form.escolaridadeDoUsuario),callback:function ($$v) {_vm.$set(_vm.form, "escolaridadeDoUsuario", $$v)},expression:"form.escolaridadeDoUsuario"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Opção não encontrada ")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Estado de nascimento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Estado de nascimento","label-for":"estado-nascimento","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"estado-nascimento","options":_vm.ufNascimentoDoUsuario,"placeholder":"Selecione uma opção","selectable":function (option) { return !option.value.includes('select_value'); },"disabled":_vm.mode === 'V',"label":"text"},model:{value:(_vm.form.ufNascimentoDoUsuario),callback:function ($$v) {_vm.$set(_vm.form, "ufNascimentoDoUsuario", $$v)},expression:"form.ufNascimentoDoUsuario"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Opção não encontrada ")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Cidade de nascimento","label-for":"cidadeDeNascimento"}},[_c('validation-provider',{attrs:{"name":"Cidade de nascimento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0
                        ? 'form-control is-invalid'
                        : 'form-control',attrs:{"id":"cidadeDeNascimento","disabled":_vm.mode === 'V'},model:{value:(_vm.form.cidadeDeNascimento),callback:function ($$v) {_vm.$set(_vm.form, "cidadeDeNascimento", $$v)},expression:"form.cidadeDeNascimento"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Gerente","label-for":"gerente"}},[_c('v-select',{attrs:{"id":"gerente","placeholder":"Nenhum","label":"text","options":_vm.listaGerentes,"autocomplete":"true","value":_vm.rolesList.idDaFuncao,"selectable":function (option) { return !option.value.includes('select_value'); },"disabled":_vm.mode === 'V'},model:{value:(_vm.form.gerenteDoUsuario),callback:function ($$v) {_vm.$set(_vm.form, "gerenteDoUsuario", $$v)},expression:"form.gerenteDoUsuario"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Opção não encontrada ")])])],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Gestor","label-for":"gestor"}},[_c('v-select',{attrs:{"id":"gestor","options":_vm.listaGestores,"selectable":function (option) { return !option.value.includes('select_value'); },"disabled":_vm.mode === 'V',"placeholder":"Nenhum","label":"text"},model:{value:(_vm.form.gestorDoUsuario),callback:function ($$v) {_vm.$set(_vm.form, "gestorDoUsuario", $$v)},expression:"form.gestorDoUsuario"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Opção não encontrada ")])])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }