<template>
  <div>
    <form-wizard
      color="#0078d4"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="buttonLabel"
      next-button-text="Próximo"
      back-button-text="Anterior"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <!-- tipo / (nome  ou nome fantasia) / telefone / celular -->
      <tab-content
        title="Detalhes do cliente"
        :before-change="validaDadosBasicos"
      >
        <validation-observer
          ref="regrasClienteDetalhe"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Detalhes do cliente
              </h5>
              <small class="text-muted">
                Entre com as informações para cadastrar um novo cliente.
              </small>
            </b-col>

            <!-- Tipo de cliente (Pessoa Física = PF, Pessoa Jurídica = PJ) -->
            <b-col md="6">
              <b-form-group label="Tipo de cliente">
                <div class="demo-inline-spacing">
                  <b-form-radio
                    v-model="form.tipoDeCliente"
                    name="tipo-de-cliente"
                    value="PJ"
                    :disabled="mode === 'V' || mode === 'A'"
                  >
                    Pessoa Jurídica
                  </b-form-radio>
                  <b-form-radio
                    v-model="form.tipoDeCliente"
                    name="tipo-de-cliente"
                    value="PF"
                    :disabled="mode === 'V' || mode === 'A'"
                  >
                    Pessoa Física
                  </b-form-radio>
                </div>
              </b-form-group>
            </b-col>

            <b-col
              v-if="form.tipoDeCliente === 'PJ'"
              md="6"
            >
              <validation-provider
                #default="{ errors }"
                name="Simples Nacional"
                rules="required"
              >
                <b-form-group label="Simples Nacional">
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="form.simplesNacional"
                      name="simples-nacional"
                      value="S"
                      :disabled="mode === 'V'"
                    >
                      Sim
                    </b-form-radio>
                    <b-form-radio
                      v-model="form.simplesNacional"
                      name="simples-nacional"
                      value="N"
                      :disabled="mode === 'V'"
                    >
                      Não
                    </b-form-radio>
                  </div>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              v-if="form.tipoDeCliente === 'PF'"
              md="6"
            >
              <b-form-group
                label="Nome"
                label-for="nomePessoaFisica"
              >
                <validation-provider
                  #default="{ errors }"
                  vid="nomePessoaFisica"
                  name="Nome"
                  rules="required"
                >
                  <b-form-input
                    id="nomePessoaFisica"
                    v-model="form.nomePessoaFisica"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                    @focus="limpaNome"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-else
              md="6"
            >
              <b-form-group
                label="Nome Fantasia"
                label-for="nomeFantasia"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nome Fantasia"
                  rules="required"
                >
                  <b-form-input
                    id="nomeFantasia"
                    v-model="form.nomeFantasia"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                    @focus="limpaNome"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="form.tipoDeCliente === 'PF'"
              md="6"
            >
              <b-form-group
                label="CPF"
                label-for="cpf"
              >
                <validation-provider
                  #default="{ errors }"
                  name="CPF"
                  rules="required"
                >
                  <cleave
                    id="cpf"
                    v-model="form.cpf"
                    class="form-control"
                    :class="errors.length > 0 ? 'is-invalid' : ''"
                    :options="options.cpf"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="form.tipoDeCliente === 'PJ'"
              md="6"
            >
              <b-form-group
                label="CNPJ"
                label-for="cnpj"
              >
                <validation-provider
                  #default="{ errors }"
                  name="CNPJ"
                  rules="required|min:8"
                >
                  <cleave
                    id="cnpj"
                    v-model="form.cnpj"
                    class="form-control"
                    :class="errors.length > 0 ? 'is-invalid' : ''"
                    :options="options.cnpj"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <!-- Telefone Fixo -->
              <b-form-group
                label="Telefone Fixo"
                label-for="telefoneFixo"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Telefone Fixo"
                  vid="telFixo"
                  rules="required|telFixo"
                >
                  <cleave
                    id="telefoneFixo"
                    v-model="form.telefoneFixo"
                    class="form-control"
                    :class="errors.length > 0 ? 'is-invalid' : ''"
                    :raw="false"
                    :options="options.telefoneFixo"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="form.tipoDeCliente === 'PJ'"
              md="6"
            >
              <b-form-group
                label="Celular"
                label-for="celular"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Celular"
                  vid="celular"
                  rules="required|celular"
                >
                  <cleave
                    id="celular"
                    v-model="form.celular"
                    class="form-control"
                    :options="options.celular"
                    :class="errors.length > 0 ? 'is-invalid' : ''"
                    :raw="false"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="form.tipoDeCliente === 'PF'"
              md="6"
            >
              <b-form-group
                label="Celular"
                label-for="celular"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Celular"
                  vid="celular"
                  rules="required|celular"
                >
                  <cleave
                    id="celular"
                    v-model="form.celular"
                    class="form-control"
                    :options="options.celular"
                    :class="errors.length > 0 ? 'is-invalid' : ''"
                    :raw="false"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="form.tipoDeCliente === 'PF'"
              md="6"
            >
              <b-form-group
                label="Formação"
                label-for="formacao"
              >
                <validation-provider
                  #default="{ errors }"
                  vid="formacaoDoCliente"
                  name="Formação"
                  rules="required"
                >
                  <b-form-input
                    id="formacaoDoCliente"
                    v-model="form.formacaoDoCliente"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="form.tipoDeCliente === 'PJ'"
              md="6"
            >
              <b-form-group
                label="Inscrição Estadual"
                label-for="inscricaoEstadual"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Inscrição Estadual"
                  rules="required"
                >
                  <b-form-input
                    id="inscricaoEstadual"
                    v-model="form.inscricaoEstadual"
                    maxlength="25"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="form.tipoDeCliente === 'PJ'"
              md="6"
            >
              <b-form-group
                label="Razão Social"
                label-for="razaoSocial"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Razão Social"
                  rules="required"
                >
                  <b-form-input
                    id="razaoSocial"
                    v-model="form.razaoSocial"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="form.tipoDeCliente === 'PF'"
              md="6"
            >
              <b-form-group
                label="Número de registro"
                label-for="numeroRegistro"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Número de registro"
                  rules="required"
                >
                  <b-form-input
                    id="numeroRegistro"
                    v-model="form.numeroRegistro"
                    maxlength="25"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="form.tipoDeCliente === 'PJ'"
              md="6"
            >
              <b-form-group
                label="Inscrição Municipal"
                label-for="inscricaoMunicipal"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Inscrição Municipal"
                  rules="required"
                >
                  <b-form-input
                    id="inscricaoMunicipal"
                    v-model="form.inscricaoMunicipal"
                    maxlength="25"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Email Cliente"
                label-for="emailCliente"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email Cliente"
                  rules="required|email"
                >
                  <b-form-input
                    id="emailCliente"
                    v-model="form.emailCliente"
                    type="email"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- cep / logradouro / numero / complemento / bairro / municipio / estado -->
      <tab-content
        title="Endereço"
        :before-change="validaEndereco"
      >
        <validation-observer
          ref="regrasClienteEndereco"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Informações de endereço
              </h5>
              <small
                class="text-muted"
              >Insira os dados de endereço do cliente.</small>
            </b-col>

            <b-col md="6">
              <!-- CEP -->
              <validation-provider
                #default="{ errors }"
                name="CEP"
                rules="required"
              >
                <b-form-group
                  label="CEP"
                  label-for="cep"
                >
                  <cleave
                    id="cep"
                    v-model="form.cep"
                    class="form-control"
                    :raw="false"
                    :options="options.cep"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                    @input="getCep()"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <!-- Logradouro -->
              <validation-provider
                #default="{ errors }"
                name="Logradouro"
                rules="required"
              >
                <b-form-group
                  label="Logradouro"
                  label-for="logradouro"
                >
                  <b-form-input
                    id="logradouro"
                    v-model="form.logradouro"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Logradouro Número -->
              <validation-provider
                #default="{ errors }"
                name="Número"
                rules="required"
              >
                <b-form-group
                  label="Número"
                  label-for="logradouroNumero"
                >
                  <b-form-input
                    id="logradouroNumero"
                    v-model="form.logradouroNumero"
                    maxlength="25"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Complemento -->
              <b-form-group
                label="Complemento"
                label-for="complemento"
              >
                <b-form-input
                  id="complemento"
                  v-model="form.complemento"
                  :disabled="mode === 'V'"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <!-- Bairro -->
              <validation-provider
                #default="{ errors }"
                name="Bairro"
                rules="required"
              >
                <b-form-group
                  label="Bairro"
                  label-for="bairro"
                >
                  <b-form-input
                    id="bairro"
                    v-model="form.bairro"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Município -->
              <validation-provider
                #default="{ errors }"
                name="Município"
                rules="required"
              >
                <b-form-group
                  label="Município"
                  label-for="municipio"
                >
                  <b-form-input
                    id="municipio"
                    v-model="form.municipio"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Estado -->
              <validation-provider
                #default="{ errors }"
                name="Estado"
                rules="required"
              >
                <b-form-group
                  label="Estado"
                  label-for="estado"
                >
                  <b-form-input
                    id="estado"
                    v-model="form.estado"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Local de trabalho"
                label-for="titulo"
              >
                <validation-provider
                  #default="{ errors }"
                  vid="titulo"
                  name="Local de trabalho"
                  rules="required"
                >
                  <b-form-input
                    id="titulo"
                    v-model="form.titulo"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- site / instagram / linkedin / facebook -->
      <tab-content title="Links Sociais">
        <!-- :before-change="validationFormSocial" -->
        <validation-observer
          ref="regrasSocial"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Links
              </h5>
              <small
                class="text-muted"
              >Insira os links das duas redes sociais.</small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Site"
                label-for="Site"
              >
                <b-form-input
                  id="site"
                  v-model="form.siteUrl"
                  :disabled="mode === 'V'"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Instagram"
                label-for="instagram"
              >
                <b-form-input
                  id="instagram"
                  v-model="form.instagramUrl"
                  :disabled="mode === 'V'"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="LinkedIn"
                label-for="linkedin"
              >
                <b-form-input
                  id="linkedin"
                  v-model="form.linkedinUrl"
                  :disabled="mode === 'V'"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Facebook"
                label-for="facebook"
              >
                <b-form-input
                  id="facebook"
                  v-model="form.facebookUrl"
                  :disabled="mode === 'V'"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- Faturamento -->
      <tab-content
        v-if="form.tipoDeCliente === 'PJ'"
        title="Faturamento"
        :before-change="validaFaturamento"
      >
        <validation-observer
          ref="regrasFaturamento"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Informações de faturamento
              </h5>
              <small
                class="text-muted"
              >Insira os dados de faturamento do cliente.</small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nome do responsável pelo faturamento"
                label-for="nomeFaturamento"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nome do responsável pelo faturamento"
                  rules="required"
                >
                  <b-form-input
                    id="nomeFaturamento"
                    v-model="form.nomeFaturamento"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Telefone Faturamento"
                vid="telFixo"
                rules="required|telFixo"
              >
                <b-form-group
                  label="Telefone faturamento"
                  label-for="telefoneFaturamento"
                >
                  <cleave
                    id="telefoneFaturamento"
                    v-model="form.telefoneFaturamento"
                    class="form-control"
                    :raw="false"
                    :options="options.telefoneFaturamento"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Email Faturamento"
                label-for="emailFaturamento"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email Faturamento"
                  rules="required|email"
                >
                  <b-form-input
                    id="emailFaturamento"
                    v-model="form.emailFaturamento"
                    type="email"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Data de faturamento"
                label-for="dataFaturamento"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Data de faturamento"
                  rules="required"
                >
                  <b-form-input
                    id="dataFaturamento"
                    v-model="form.dataFaturamento"
                    class="form-control"
                    type="text"
                    :raw="false"
                    :class="errors.length > 0 ? 'is-invalid' : ''"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- Responsável -->
      <tab-content
        v-if="form.tipoDeCliente === 'PJ'"
        title="Responsável"
        :before-change="validaResponsavel"
      >
        <validation-observer
          ref="regrasResponsavel"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Informações do responsável
              </h5>
              <small
                class="text-muted"
              >Insira os dados do responsável do cliente.</small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nome do responsável"
                label-for="nomeResponsavel1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nome do responsável"
                  rules="required"
                >
                  <b-form-input
                    id="nomeResponsavel1"
                    v-model="form.nomeResponsavel1"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Cargo do responsável"
                label-for="cargoResponsavel1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Cargo do responsável"
                  rules="required"
                >
                  <b-form-input
                    id="cargoResponsavel1"
                    v-model="form.cargoResponsavel1"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Departamento do responsável"
                label-for="departamentoResponsavel1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Departamento do responsável"
                  rules="required"
                >
                  <b-form-input
                    id="departamentoResponsavel1"
                    v-model="form.departamentoResponsavel1"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Matrícula do responsável"
                label-for="matriculaResponsavel1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Matrícula do responsável"
                  rules="required"
                >
                  <b-form-input
                    id="matriculaResponsavel1"
                    v-model="form.matriculaResponsavel1"
                    type="text"
                    maxlength="25"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="CPF do responsável"
                label-for="cpfResponsavel1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="CPF do responsável"
                  rules="required"
                >
                  <cleave
                    id="cpfResponsavel1"
                    v-model="form.cpfResponsavel1"
                    class="form-control"
                    :options="options.cpfResponsavel1"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Email do responsável"
                label-for="emailResponsavel1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email do responsável"
                  rules="required|email"
                >
                  <b-form-input
                    id="emailResponsavel1"
                    v-model="form.emailResponsavel1"
                    type="email"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Profissão do responsável"
                label-for="profissaoResponsavel1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Profissão do responsável"
                  rules="required"
                >
                  <b-form-input
                    id="profissaoResponsavel1"
                    v-model="form.profissaoResponsavel1"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Registro de classe do responsável"
                label-for="registroDeClasseResponsavel1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Registro de classe do responsável"
                >
                  <b-form-input
                    id="registroDeClasseResponsavel1"
                    v-model="form.registroDeClasseResponsavel1"
                    maxlength="25"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Adicionar segundo responsável?">
                <div class="demo-inline-spacing">
                  <b-form-radio
                    v-model="form.adicionaSegundoResponsavel"
                    name="checa-segundo-responsavel"
                    value="S"
                    :disabled="mode === 'V'"
                  >
                    Sim
                  </b-form-radio>
                  <b-form-radio
                    v-model="form.adicionaSegundoResponsavel"
                    name="checa-segundo-responsavel"
                    value="N"
                    :disabled="mode === 'V'"
                    @change="limpaCamposResp2()"
                  >
                    Não
                  </b-form-radio>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-if="form.adicionaSegundoResponsavel === 'S'"
            class="mt-1"
          >
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Informações do segundo responsável
              </h5>
              <small
                class="text-muted"
              >Insira os dados do responsável do cliente.</small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nome do segundo responsável"
                label-for="nomeResponsavel2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nome do segundo responsável"
                  rules="required"
                >
                  <b-form-input
                    id="nomeResponsavel2"
                    v-model="form.nomeResponsavel2"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Cargo do segundo responsável"
                label-for="cargoResponsavel2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Cargo do segundo responsável"
                  rules="required"
                >
                  <b-form-input
                    id="cargoResponsavel2"
                    v-model="form.cargoResponsavel2"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Departamento do segundo responsável"
                label-for="departamentoResponsavel2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Departamento do segundo responsável"
                  rules="required"
                >
                  <b-form-input
                    id="departamentoResponsavel2"
                    v-model="form.departamentoResponsavel2"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Matrícula do segundo responsável"
                label-for="matriculaResponsavel2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Matrícula do segundo responsável"
                  rules="required"
                >
                  <b-form-input
                    id="matriculaResponsavel2"
                    v-model="form.matriculaResponsavel2"
                    type="text"
                    maxlength="25"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="CPF do responsável 2"
                label-for="cpfResponsavel2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="CPF do responsável 2"
                  rules="required"
                >
                  <cleave
                    id="cpfResponsavel2"
                    v-model="form.cpfResponsavel2"
                    class="form-control"
                    :options="options.cpfResponsavel2"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Email do segundo responsável"
                label-for="emailResponsavel2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email do segundo responsável"
                  rules="required|email"
                >
                  <b-form-input
                    id="emailResponsavel2"
                    v-model="form.emailResponsavel2"
                    type="email"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Profissão do segundo responsável"
                label-for="profissaoResponsavel2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Profissão do segundo responsável"
                  rules="required"
                >
                  <b-form-input
                    id="profissaoResponsavel2"
                    v-model="form.profissaoResponsavel2"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Registro de classe do segundo responsável"
                label-for="registroDeClasseResponsavel2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Registro de classe do segundo responsável"
                >
                  <b-form-input
                    id="registroDeClasseResponsavel2"
                    v-model="form.registroDeClasseResponsavel2"
                    maxlength="25"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <tab-content
        title="Destinatário"
        :before-change="validaInfoMedico"
      >
        <!-- :before-change="validationFormMedico" -->
        <validation-observer
          ref="regrasMedico"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Informações do Destinatário
              </h5>
              <small
                class="text-muted"
              >Insira os dados do Destinatário do Cliente</small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nome do Destinatário"
                label-for="nomeDoMedico"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nome do Destinatário"
                  rules="required"
                >
                  <b-form-input
                    id="nomeDoMedico"
                    v-model="form.nomeDoMedico"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Email do Destinatário"
                label-for="emailDoMedico"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email do Destinatário"
                  rules="required|email"
                >
                  <b-form-input
                    id="emailDoMedico"
                    v-model="form.emailDoMedico"
                    type="email"
                    :state="errors.length > 0 ? false : null"
                    :disabled="mode === 'V'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
/* eslint-disable */
import { FormWizard, TabContent } from "vue-form-wizard"
import vSelect from "vue-select"
import { ValidationObserver, ValidationProvider, extend } from "vee-validate"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import "vue-form-wizard/dist/vue-form-wizard.min.css"
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadio
  // BInputGroupPrepend,
  // BInputGroup,
} from "bootstrap-vue"
import { required, email, min } from "@validations"
import Cleave from "vue-cleave-component"
import { codeIcon, codeMask } from "../Registration/code"

import useJwt from "@/auth/jwt/useJwt"

export default {
  components: {
    // BInputGroupPrepend,
    // BInputGroup,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadio
  },
  props: {
    mode: {
      type: String, // I -> Incluir || A -> Alterar || V -> Visualização
      required: true
    },
    id: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      codeMask,
      required,
      min,
      codeIcon,
      email,
      buttonLabel: "Salvar",
      form: {
        // Dados Principais Cliente
        id: 0,
        tipoDeCliente: "PJ", // PJ -> Pessoa Jurídica | PF Pessoa Física
        telefoneFixo: "",
        celular: "",
        emailCliente: "",
        simplesNacional: "",
        // Cliente Endereço:
        cep: "",
        logradouro: "",
        logradouroNumero: "",
        complemento: "",
        bairro: "",
        municipio: "",
        estado: "",
        titulo: "",
        // Cliente Sociais:
        siteUrl: "",
        facebookUrl: "",
        instagramUrl: "",
        linkedinUrl: "",
        // Dados Cliente Pessoa Física
        nomePessoaFisica: "",
        cpf: "",
        formacaoDoCliente: "",
        numeroRegistro: "",
        // Dados Cliente Pessoa Jurídica
        razaoSocial: "",
        nomeFantasia: "",
        cnpj: "",
        inscricaoEstadual: "",
        inscricaoMunicipal: "",
        nomeFaturamento: "",
        telefoneFaturamento: "",
        emailFaturamento: "",
        dataFaturamento: "",
        nomeResponsavel1: "",
        cargoResponsavel1: "",
        departamentoResponsavel1: "",
        matriculaResponsavel1: "",
        cpfResponsavel1: "",
        emailResponsavel1: "",
        profissaoResponsavel1: "",
        registroDeClasseResponsavel1: "",
        adicionaSegundoResponsavel: "N",
        nomeResponsavel2: "",
        cargoResponsavel2: "",
        departamentoResponsavel2: "",
        matriculaResponsavel2: "",
        cpfResponsavel2: "",
        emailResponsavel2: "",
        profissaoResponsavel2: "",
        registroDeClasseResponsavel2: "",
        // Dados do Médico
        nomeDoMedico: "",
        emailDoMedico: ""
      },
      options: {
        cnpj: {
          delimiters: [".", ".", "/", "-"],
          blocks: [2, 3, 3, 4, 2],
          numericOnly: true
        },
        cpf: {
          delimiters: [".", ".", "-"],
          blocks: [3, 3, 3, 2],
          numericOnly: true
        },
        cpfResponsavel1: {
          delimiters: [".", ".", "-"],
          blocks: [3, 3, 3, 2],
          numericOnly: true
        },
        cpfResponsavel2: {
          delimiters: [".", ".", "-"],
          blocks: [3, 3, 3, 2],
          numericOnly: true
        },
        telefoneFaturamento: {
          delimiters: ["(", ")", " ", "-"],
          blocks: [0, 2, 0, 4, 4],
          numericOnly: true
        },
        // dataFaturamento: {
        //   date: false,
        //   delimiter: '/',
        //   datePattern: ['d', 'm', 'Y'],
        // },

        telefoneFixo: {
          delimiters: ["(", ")", " ", "-"],
          blocks: [0, 2, 0, 4, 4],
          numericOnly: true
        },
        celular: {
          delimiters: ["(", ")", " ", "-"],
          blocks: [0, 2, 0, 5, 4],
          numericOnly: true
        },
        cep: {
          delimiters: [".", "-"],
          blocks: [2, 3, 3],
          numericOnly: true
        }
      },
      formacaoGrau: [
        { value: "select_value", text: "Selecione uma opção" },
        { value: "Fundamental incompleto", text: "Fundamental incompleto" },
        { value: "Fundamental cursando", text: "Fundamental cursando" },
        { value: "Fundamental completo", text: "Fundamental completo" },
        { value: "Ensino médio incompleto", text: "Ensino médio incompleto" },
        { value: "Ensino médio cursando", text: "Ensino médio cursando" },
        { value: "Ensino médio concluído", text: "Ensino médio concluído" },
        {
          value: "Ensino superior incompleto",
          text: "Ensino superior incompleto"
        },
        { value: "Ensino superior cursando", text: "Ensino superior cursando" },
        { value: "Ensino superior completo", text: "Ensino superior completo" }
      ]
    }
  },
  watch: {},
  computed: {
    cpfValidate(cpf) {
      if (cpf.length > 1) {
        if (typeof cpf !== "string") return false
        cpf = cpf.replace(/[\s.-]*/gim, "")
        if (
          !cpf ||
          cpf.length !== 11 ||
          cpf === "00000000000" ||
          cpf === "11111111111" ||
          cpf === "22222222222" ||
          cpf === "33333333333" ||
          cpf === "44444444444" ||
          cpf === "55555555555" ||
          cpf === "66666666666" ||
          cpf === "77777777777" ||
          cpf === "88888888888" ||
          cpf === "99999999999"
        ) {
          return false
        }

        let soma = 0
        let resto
        for (let i = 1; i <= 9; i++)
          soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
        resto = (soma * 10) % 11
        if (resto === 10 || resto === 11) resto = 0
        if (resto !== parseInt(cpf.substring(9, 10))) return false
        soma = 0
        for (let i = 1; i <= 10; i++)
          soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
        resto = (soma * 10) % 11
        if (resto === 10 || resto === 11) resto = 0
        if (resto !== parseInt(cpf.substring(10, 11))) return false
        return true
      } else {
        return false
      }
    }
  },
  mounted() {
    this.handleViewMode()
    if (this.mode === "V") {
      this.buttonLabel = "Fechar"
    } else {
      this.buttonlabel = "Salvar"
    }
  },
  methods: {
    async handleViewMode() {
      if (this.mode === "V" || this.mode === "A") {
        const id = this.id
        const resp = await useJwt
          .getClienteById(id)
          .then(response => response.data)
          .catch(response => response.data)

        if (resp && resp.sucesso) {
          if (resp.dados.consultarCliente.tipoDoCliente === "F") {
            this.handleFieldsPF(resp.dados.consultarCliente)
          } else {
            this.handleFieldsPJ(resp.dados.consultarCliente)
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                "Erro ao encontrar cliente, Por favor verifique sua conexão!",
              icon: "ErrorIcon",
              variant: "danger"
            }
          })
        }
      }
    },
    async cadastroClientePJ() {
      // console.log('Habilita r2: ', this.form.adicionaSegundoResponsavel !== 'N')
      try {
        const resp = await useJwt
          .cadastroClientePJ({
            emailDoCliente: this.form.emailCliente,
            tipoDeCliente: this.form.tipoDeCliente,
            telefoneDoCliente: this.form.telefoneFixo,
            celularDoCliente: this.form.celular,
            nomeDoMedico: this.form.nomeDoMedico,
            emailDoMedico: this.form.emailDoMedico,
            cep: this.form.cep,
            logradouro: this.form.logradouro,
            logradouroNumero: this.form.logradouroNumero,
            complemento: this.form.complemento,
            bairro: this.form.bairro,
            municipio: this.form.municipio,
            estado: this.form.estado,
            urlLinkedinDoCliente: this.form.linkedinUrl,
            urlInstagramDoCliente: this.form.instagramUrl,
            urlFacebookDoCliente: this.form.facebookUrl,
            urlSiteDoCliente: this.form.siteUrl,
            statusDoCliente: "A",
            razaoSocialDoCliente: this.form.razaoSocial,
            nomeFantasiaDoCliente: this.form.nomeFantasia,
            cnpjDoCliente: this.form.cnpj,
            titulo: this.form.titulo,
            inscricaoEstadual: this.form.inscricaoEstadual,
            inscricaoMunicipal: this.form.inscricaoMunicipal,
            simplesNacional: this.form.simplesNacional,
            nomeDoFaturamento: this.form.nomeFaturamento,
            telefoneDoFaturamento: this.form.telefoneFaturamento,
            emailDoFaturamento: this.form.emailFaturamento,
            dataDoFaturamento: this.form.dataFaturamento,
            nomeDoResponsavel1: this.form.nomeResponsavel1,
            cargoDoResposavel1: this.form.cargoResponsavel1,
            departamentoDoResponsavel1: this.form.departamentoResponsavel1,
            matriculaDoResponsavel1: this.form.matriculaResponsavel1,
            cpfDoResponsavel1: this.form.cpfResponsavel1,
            emailDoResponsavel1: this.form.emailResponsavel1,
            profissaoDoResponsavel1: this.form.profissaoResponsavel1,
            numeroDeRegistroDeClasse1: this.form.registroDeClasseResponsavel1,
            habilitaResponsavel2: this.form.adicionaSegundoResponsavel !== "N",
            nomeDoResponsavel2: this.form.nomeResponsavel2,
            cargoDoResposavel2: this.form.cargoResponsavel2,
            departamentoDoResponsavel2: this.form.departamentoResponsavel2,
            matriculaDoResponsavel2: this.form.matriculaResponsavel2,
            cpfDoResponsavel2: this.form.cpfResponsavel2,
            emailDoResponsavel2: this.form.emailResponsavel2,
            profissaoDoResponsavel2: this.form.profissaoResponsavel2,
            numeroDeRegistroDeClasse2: this.form.registroDeClasseResponsavel2,
            textoDoTermo: "exemplo"
          })
          .then(response => response.data)
          .catch(response => {
            const errorCatch = Object.assign({}, response)
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  "Houve um erro ao cadastrar cliente. Por favor, verifique os dados preenchidos.",
                icon: "EditIcon",
                variant: "danger",
                text: errorCatch.response.data.mensagem
              }
            })
          })
        if (resp.sucesso) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Cliente Cadastrado!",
              icon: "EditIcon",
              variant: "success"
            }
          })

          this.$router.push({ name: "client-page" })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                "Erro ao enviar dados. Por favor verifique os campos e tente novamente!",
              icon: "ErrorIcon",
              variant: "danger"
            }
          })
        }
      } catch (error) {
        return this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "Erro ao enviar dados. Por favor verifique se os dados utilizados estão corretos e tente novamente!",
            icon: "ErrorIcon",
            variant: "danger"
          }
        })
      }
    },
    async cadastroClientePF() {
      try {
        const resp = await useJwt
          .cadastroClientePF({
            emailDoCliente: this.form.emailCliente,
            nomeDoCliente: this.form.nomePessoaFisica,
            cpfDoCliente: this.form.cpf,
            formacaoDoCliente: this.form.formacaoDoCliente,
            numeroDeRegistroDoCliente: this.form.numeroRegistro,
            tipoDoCliente: this.form.tipoDeCliente,
            telefoneDoCliente: this.form.telefoneFixo,
            celularDoCliente: this.form.celular,
            nomeDoMedico: this.form.nomeDoMedico,
            emailDoMedico: this.form.emailDoMedico,
            cep: this.form.cep,
            logradouro: this.form.logradouro,
            logradouroNumero: this.form.logradouroNumero,
            complemento: this.form.complemento,
            bairro: this.form.bairro,
            municipio: this.form.municipio,
            estado: this.form.estado,
            titulo: this.form.titulo,
            urlLinkedinDoCliente: this.form.linkedinUrl,
            urlInstagramDoCliente: this.form.instagramUrl,
            urlFacebookDoCliente: this.form.facebookUrl,
            urlSiteDoCliente: this.form.siteUrl,
            statusDoCliente: "Ativo"
          })
          .then(response => response.data)
          .catch(response => {
            const errorCatch = Object.assign({}, response)
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  "Houve um erro ao cadastrar cliente. Por favor, verifique os dados preenchidos.",
                icon: "EditIcon",
                variant: "danger",
                text: errorCatch.response.data.mensagem
              }
            })
          })

        if (resp && resp.sucesso) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Cliente Cadastrado!",
              icon: "EditIcon",
              variant: "success"
            }
          })

          this.$router.push({ name: "client-page" })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                "Erro ao enviar dados. Por favor verifique os campos e tente novamente!",
              icon: "ErrorIcon",
              variant: "danger"
            }
          })
        }
      } catch (error) {
        return this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "Erro ao enviar dados. Por favor verifique se os dados utilizados estão corretos e tente novamente!",
            icon: "ErrorIcon",
            variant: "danger"
          }
        })
      }
    },
    async updateClientPJ() {
      const id = this.form.id
      const cep = this.form.cep.replace(/[^\d]/g, "")
      const cnpj = this.form.cnpj.replace(/[^\d]/g, "")
      const resp = await useJwt
        .updateClientPJ(id, {
          telefoneDoCliente: this.form.telefoneFixo,
          celularDoCliente: this.form.celular,
          formacaoDoCliente: this.form.formacaoDoCliente,
          numeroDeRegistroDoCliente: this.form.numeroDeRegistroDoCliente,
          titulo: this.form.titulo,
          cep: cep,
          logradouro: this.form.logradouro,
          emailDoCliente: this.form.emailCliente,
          nomeDoMedico: this.form.nomeDoMedico,
          emailDoMedico: this.form.emailDoMedico,
          logradouroNumero: this.form.logradouroNumero,
          complemento: this.form.complemento,
          bairro: this.form.bairro,
          municipio: this.form.municipio,
          estado: this.form.estado,
          urlLinkedinDoCliente: this.form.linkedinUrl,
          urlInstagramDoCliente: this.form.instagramUrl,
          urlFacebookDoCliente: this.form.facebookUrl,
          urlSiteDoCliente: this.form.siteUrl,
          razaoSocialDoCliente: this.form.razaoSocial,
          nomeFantasiaDoCliente: this.form.nomeFantasia,
          cnpjDoCliente: cnpj,
          inscricaoEstadual: this.form.inscricaoEstadual,
          inscricaoMunicipal: this.form.inscricaoMunicipal,
          simplesNacional: this.form.simplesNacional,
          nomeDoFaturamento: this.form.nomeFaturamento,
          telefoneDoFaturamento: this.form.telefoneFaturamento,
          emailDoFaturamento: this.form.emailFaturamento,
          dataDoFaturamento: this.form.dataFaturamento,
          nomeDoResponsavel1: this.form.nomeResponsavel1,
          cargoDoResponsavel1: this.form.cargoResponsavel1,
          departamentoDoResponsavel1: this.form.departamentoResponsavel1,
          matriculaDoResponsavel1: this.form.matriculaResponsavel1,
          cpfDoResponsavel1: this.form.cpfResponsavel1,
          emailDoResponsavel1: this.form.emailResponsavel1,
          profissaoDoResponsavel1: this.form.profissaoResponsavel1,
          numeroDeRegistroDeClasse1: this.form.registroDeClasseResponsavel1,
          habilitaResponsavel2:
            this.form.adicionaSegundoResponsavel === "S" ? true : false,
          nomeDoResponsavel2: this.form.nomeResponsavel2,
          cargoDoResponsavel2: this.form.cargoResponsavel2,
          departamentoDoResponsavel2: this.form.departamentoResponsavel2,
          matriculaDoResponsavel2: this.form.matriculaResponsavel2,
          cpfDoResponsavel2: this.form.cpfResponsavel2,
          emailDoResponsavel2: this.form.emailResponsavel2,
          profissaoDoResponsavel2: this.form.profissaoResponsavel2,
          numeroDeRegistroDeClasse2: this.form.registroDeClasseResponsavel2,
          textoDoTermo: "exemplo"
        })
        .then(response => response.data)
        .catch(response => {
          const errorCatch = Object.assign({}, response)
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                "Houve um erro ao atualizar esse cliente. Por favor, verifique os dados preenchidos.",
              icon: "EditIcon",
              variant: "danger",
              text: errorCatch.response.data.mensagem
            }
          })
        })

      if (resp.sucesso) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Cliente atualizado!",
            icon: "EditIcon",
            variant: "success"
          }
        })

        this.$router.push({ name: "client-page" })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "Erro ao atualizar cliente, Por favor verifique os campos e tente novamente!",
            icon: "ErrorIcon",
            variant: "danger"
          }
        })
      }
    },
    async updateClientPF() {
      const id = this.form.id
      const cep = this.form.cep.replace(/[^\d]/g, "")

      const resp = await useJwt
        .updateClientPF(id, {
          telefoneDoCliente: this.form.telefoneFixo,
          celularDoCliente: this.form.celular,
          cep: cep,
          emailDoCliente: this.form.emailCliente,
          nomeDoMedico: this.form.nomeDoMedico,
          emailDoMedico: this.form.emailDoMedico,
          logradouro: this.form.logradouro,
          logradouroNumero: this.form.logradouroNumero,
          complemento: this.form.complemento,
          bairro: this.form.bairro,
          municipio: this.form.municipio,
          estado: this.form.estado,
          titulo: this.form.titulo,
          urlLinkedinDoCliente: this.form.linkedinUrl,
          urlInstagramDoCliente: this.form.instagramUrl,
          urlFacebookDoCliente: this.form.facebookUrl,
          urlSiteDoCliente: this.form.siteUrl,
          nomeDoCliente: this.form.nomePessoaFisica,
          formacaoDoCliente: this.form.formacaoDoCliente,
          numeroDeRegistroDoCliente: this.form.numeroRegistro
        })
        .then(response => response.data)
        .catch(response => {
          const errorCatch = Object.assign({}, response)
          this.$toast({
            component: ToastificationContent,
            timeout: 10000,
            props: {
              title:
                "Houve um erro ao atualizar esse cliente. Por favor, verifique os dados preenchidos.",
              icon: "EditIcon",
              variant: "danger",
              text: errorCatch.response.data.mensagem
            }
          })
        })

      if (resp.sucesso) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Cliente atualizado!",
            icon: "EditIcon",
            variant: "success"
          }
        })

        this.$router.push({ name: "client-page" })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "Erro ao atualizar cliente, Por favor verifique os campos e tente novamente!",
            icon: "ErrorIcon",
            variant: "danger"
          }
        })
      }
    },
    handleFieldsPJ(data) {
      ;(this.form.id = data.idDoCliente),
        (this.form.tipoDeCliente = "PJ"),
        (this.form.telefoneFixo = data.telefoneDoCliente),
        (this.form.celular = data.celularDoCliente),
        (this.form.nomeDoMedico = data.nomeDoMedico),
        (this.form.emailDoMedico = data.emailDoMedico),
        (this.form.cep = data.cep),
        (this.form.logradouro = data.logradouro),
        (this.form.logradouroNumero = data.logradouroNumero),
        (this.form.complemento = data.complemento),
        (this.form.bairro = data.bairro),
        (this.form.municipio = data.municipio),
        (this.form.emailCliente = data.emailDoCliente)
      this.form.estado,
        (this.form.cidadeDeNascimento = data.cidadeDeNascimento),
        (this.form.linkedinUrl = data.urlLinkedinDoCliente),
        (this.form.instagramUrl = data.urlInstagramDoCliente),
        (this.form.facebookUrl = data.urlFacebookDoCliente),
        (this.form.siteUrl = data.urlSiteDoCliente),
        (this.form.razaoSocial = data.razaoSocialDoCliente),
        (this.form.nomeFantasia = data.nomeFantasiaDoCliente),
        (this.form.cnpj = data.cnpjDoCliente),
        (this.form.titulo = data.titulo),
        (this.form.inscricaoEstadual = data.inscricaoEstadual),
        (this.form.inscricaoMunicipal = data.inscricaoMunicipal),
        (this.form.simplesNacional = data.simplesNacional),
        (this.form.nomeFaturamento = data.nomeDoFaturamento),
        (this.form.telefoneFaturamento = data.telefoneDoFaturamento),
        (this.form.emailFaturamento = data.emailDoFaturamento),
        (this.form.dataFaturamento = data.dataDoFaturamento),
        (this.form.nomeResponsavel1 = data.nomeDoResponsavel1),
        (this.form.cargoResponsavel1 = data.profissaoDoResponsavel1),
        (this.form.departamentoResponsavel1 = data.departamentoDoResponsavel1),
        (this.form.matriculaResponsavel1 = data.matriculaDoResponsavel1),
        (this.form.cpfResponsavel1 = data.cpfDoResponsavel1),
        (this.form.emailResponsavel1 = data.emailDoResponsavel1),
        (this.form.profissaoResponsavel1 = data.profissaoDoResponsavel1),
        (this.form.registroDeClasseResponsavel1 =
          data.numeroDeRegistroDeClasse1),
        (this.form.adicionaSegundoResponsavel = !data.nomeDoResponsavel2
          ? "N"
          : "S"),
        (this.form.nomeResponsavel2 = data.nomeDoResponsavel2),
        (this.form.cargoResponsavel2 = data.profissaoDoResponsavel2),
        (this.form.departamentoResponsavel2 = data.departamentoDoResponsavel2),
        (this.form.matriculaResponsavel2 = data.matriculaDoResponsavel2),
        (this.form.cpfResponsavel2 = data.cpfDoResponsavel2),
        (this.form.emailResponsavel2 = data.emailDoResponsavel2),
        (this.form.profissaoResponsavel2 = data.profissaoDoResponsavel2),
        (this.form.registroDeClasseResponsavel2 =
          data.numeroDeRegistroDeClasse2)
    },
    handleFieldsPF(data) {
      ;(this.form.id = data.idDoCliente),
        (this.form.nomePessoaFisica = data.nomeDoCliente),
        (this.form.cpf = data.cpfDoCliente),
        (this.form.emailCliente = data.emailDoCliente),
        (this.form.nomeDoMedico = data.nomeDoMedico),
        (this.form.emailDoMedico = data.emailDoMedico),
        (this.form.formacaoDoCliente = data.formacaoDoCliente),
        (this.form.numeroRegistro = data.numeroDeRegistroDoCliente),
        (this.form.tipoDeCliente = "PF"),
        (this.form.telefoneFixo = data.telefoneDoCliente),
        (this.form.celular = data.celularDoCliente),
        (this.form.cep = data.cep),
        (this.form.logradouro = data.logradouro),
        (this.form.logradouroNumero = data.logradouroNumero),
        (this.form.complemento = data.complemento),
        (this.form.bairro = data.bairro),
        (this.form.municipio = data.municipio),
        this.form.estado,
        (this.form.titulo = data.titulo),
        (this.form.linkedinUrl = data.urlLinkedinDoCliente),
        (this.form.instagramUrl = data.urlInstagramDoCliente),
        (this.form.facebookUrl = data.urlFacebookDoCliente),
        (this.form.siteUrl = data.urlSiteDoCliente)
    },
    limpaNome(e) {
      const nomeId = e.target.id
      if (nomeId === "nomeFantasia") {
        this.form.nomePessoaFisica = null
      } else {
        this.form.nomeFantasia = null
      }
    },
    printPhone(e) {
      e.preventDefault()
      const telefoneFixo = this.form.telefoneFixo.replace(/\D/g, "")
      // console.log(telefoneFixo)
    },
    formSubmitted() {
      if (this.mode !== "V") {
        // Verificar se é PF ou PJ e disparar regra de cadastro de acordo com verificação
        if (this.form.tipoDeCliente === "PJ") {
          if (this.form.id === 0) this.cadastroClientePJ()
          else this.updateClientPJ()
        } else {
          if (this.form.id === 0) this.cadastroClientePF()
          else {
            this.updateClientPF()
          }
        }
      } else {
        this.$router.push({ name: "client-page" })
      }
    },
    validaDetalhe() {
      return new Promise((resolve, reject) => {
        this.$refs.regrasClienteDetalhe.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validaEndereco() {
      return new Promise((resolve, reject) => {
        this.$refs.regrasClienteEndereco.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validaFaturamento() {
      return new Promise((resolve, reject) => {
        this.$refs.regrasFaturamento.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validaInfoMedico() {
      return new Promise((resolve, reject) => {
        this.$refs.regrasMedico.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validaResponsavel() {
      return new Promise((resolve, reject) => {
        this.$refs.regrasResponsavel.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    // eslint-disable-next-line consistent-return
    async getCep() {
      if (this.form.cep.length === 10) {
        const cep = this.form.cep.replace(/[^\d]/g, "")

        const resp = await useJwt
          .getCep(cep)
          .then(response => response.data)
          .catch(response => response.data)

        if (resp) {
          this.form.logradouro = resp.logradouro
          this.form.bairro = resp.bairro
          this.form.municipio = resp.localidade
          this.form.estado = resp.uf
        }
        //  Faz o tratamento de erro na tela
      }
    },
    async validaDadosBasicos() {
      const resp = await useJwt
        .validarCamposCliente({
          idCliente: this.form.id,
          cnpj: this.form.cnpj,
          cpf: this.form.cpf,
          inscricaoEstadual: this.form.inscricaoEstadual,
          inscricaoMunicipal: this.form.inscricaoMunicipal,
          numeroDeRegistro: this.form.numeroRegistro,
          email: this.form.emailCliente,
          telefone: this.form.telefoneFixo,
          celular: this.form.celular
        })
        .then(response => response.data)
        .catch(response => {
          this.importErrors = Object.assign({}, response)
          this.errorUsers = []
          this.errorUsers = Object.assign(
            [],
            this.importErrors.response.data.mensagem
          )
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Por favor, verifique os campos novamente!",
              icon: "ErrorIcon",
              variant: "danger",
              text: this.importErrors.response.data.mensagem
            }
          })
        })
      if (resp.sucesso) {
        return new Promise((resolve, reject) => {
          this.$refs.regrasClienteDetalhe.validate().then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
        })
      }
    },
    limpaCamposResp2() {
      ;(this.form.nomeResponsavel2 = ""),
        (this.form.cargoResponsavel2 = ""),
        (this.form.departamentoResponsavel2 = ""),
        (this.form.matriculaResponsavel2 = ""),
        (this.form.cpfResponsavel2 = ""),
        (this.form.emailResponsavel2 = ""),
        (this.form.profissaoResponsavel2 = ""),
        (this.form.registroDeClasseResponsavel2 = "")
    }
  }
}
</script>
